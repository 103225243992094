import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';
import Button from 'Components/button';
import loc from 'Components/languages';
import LoadingOverlay from 'Components/loading-overlay';
import { getPayments } from 'Redux/actions';
import ButtonAdditionalOptions from '../components/ButtonAdditionOptions.jsx';
import PaymentMethods from './components/PaymentMethods.jsx';
import PaymentDetails from './components/PaymentDetails.jsx';
import ModalCashCoupon from './components/ModalCashCoupon.jsx';
import ModalDepositAmount from './components/ModalDepositAmount.jsx';
import styles from './PageSalesOrderPayment.less';

class PageSalesOrderPayment extends PureComponent {
	constructor() {
		super();
		this.state = {
			paymentMethods: new Map(),
			showModalCashCoupon: false,
			showModalDepositAmount: false,
		};
		this.isQOrder = this.isQOrder.bind(this);
		this.onChangeDepositAmountHandler = this.onChangeDepositAmountHandler.bind(this);
		this.onClickContinueHandler = this.onClickContinueHandler.bind(this);
		this.onSelectPaymentMethodHandler = this.onSelectPaymentMethodHandler.bind(this);
		this.onToggleModalCashCouponHandler = this.onToggleModalCashCouponHandler.bind(this);
		this.onToggleModalDepositAmountHandler = this.onToggleModalDepositAmountHandler.bind(this);
		this.onAddCashCouponHandler = this.onAddCashCouponHandler.bind(this);
	}

	componentDidMount() {
		this.props.getPayments({
			payload: {
				is_current_shop: true,
			},
		});
		if (this.isQOrder()) {
			this.setState({
				isFirstShownModalDepositAmount: true,
				showModalDepositAmount: true,
			});
		}
	}

	componentDidUpdate(prevProps) {
		const paymentsInfo = this.props.paymentsInfo;
		const prevPaymentsInfo = prevProps.paymentsInfo;
		if (paymentsInfo && paymentsInfo !== prevPaymentsInfo) {
			let paymentMethods = {};
			paymentsInfo && paymentsInfo.data && paymentsInfo.data.forEach((paymentInfo) => {
				paymentMethods[paymentInfo.code] = new Map(paymentInfo);
			});
			this.setState({
				paymentMethods: new Map(paymentMethods),
			});
		}
	}

	noOp() {}

	isQOrder() {
		const { salesOrder } = this.props;
		return salesOrder.get('doc_type') === 'DP1';
	}

	onChangeDepositAmountHandler(depositAmount) {
		this.props.onChangeDepositAmount(depositAmount);
		this.setState({
			isFirstShownModalDepositAmount: false,
			showModalDepositAmount: false,
		});
	}

	onClickContinueHandler(e) {
		e.preventDefault();
		const { next, onStepClick, salesOrder, paymentsInfo } = this.props;
		const isLoading = !paymentsInfo || !paymentsInfo.dataFetched || this.props.isLoading;
		// Validate payments
		if (salesOrder.get('remaining_amount') > 0) {
			alert(loc.TRANS0016);
		} else if (!isLoading) {
			onStepClick(next);
		}
	}

	onSelectPaymentMethodHandler(paymentMethod) {
		const { onAddTransactionPayment, salesOrder } = this.props;
		if (paymentMethod.code === 'CP' || paymentMethod.code === 'GC') {
			this.onToggleModalCashCouponHandler(paymentMethod.code);
		} else {
			onAddTransactionPayment(new Map({
				pay_code: paymentMethod.code,
				amount: salesOrder.get('remaining_amount'),
			}));
		}
	}

	onToggleModalCashCouponHandler(payCode = null) {
		const { showModalCashCoupon } = this.state;
		this.setState({
			showModalCashCoupon: showModalCashCoupon ? false : payCode,
		});
	}

	onToggleModalDepositAmountHandler() {
		const { showModalDepositAmount } = this.state;
		this.setState({
			showModalDepositAmount: !showModalDepositAmount,
		});
	}

	onAddCashCouponHandler(data) {
		const { showModalCashCoupon } = this.state;
		const { onAddTransactionPayment } = this.props;
		onAddTransactionPayment(new Map({
			pay_code: showModalCashCoupon || 'CP',
			amount: data.amount,
			coupon_code: data.coupon_code,
		}));
	}

	render() {
		const { paymentMethods, isFirstShownModalDepositAmount, showModalCashCoupon, showModalDepositAmount } = this.state;
		const { paymentsInfo, exchangeRatesInfo, salesOrder, isDisabled, onChangeTransactionPayment, onRemoveTransactionPayment, onToggleModalSpecialOptions } = this.props;
		const isLoading = this.props.isLoading || !paymentsInfo || !paymentsInfo.dataFetched || !exchangeRatesInfo || !exchangeRatesInfo.dataFetched;
		const isQOrder = this.isQOrder();
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div
						className={ classnames('stepContainer', 'uk-height-large', styles.container) }
						data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
					>
						<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">{ loc.payment }</h3>
						<div className="uk-width-1-1 uk-child-width-1-2 uk-flex-inline">
							<PaymentMethods
								salesOrder={ salesOrder }
								onSelect={ this.onSelectPaymentMethodHandler }
							/>
							<PaymentDetails
								salesOrder={ salesOrder }
								paymentMethods={ paymentMethods }
								onFocusDepositAmount={ this.onToggleModalDepositAmountHandler }
								onChangeTransactionPayment={ onChangeTransactionPayment }
								onRemoveTransactionPayment={ onRemoveTransactionPayment }
							/>
						</div>
						<div className="uk-position-bottom-right">
							<ButtonAdditionalOptions
								disabled={ isDisabled }
								onClick={ onToggleModalSpecialOptions }
							/>
							<Button
								text={ loc.preview }
								theme="primary"
								arrow={ true }
								onClick={ this.onClickContinueHandler }
							/>
						</div>
					</div>
				</LoadingOverlay>

				<ModalCashCoupon
					isOpen={ showModalCashCoupon }
					onToggle={ this.onToggleModalCashCouponHandler }
					onChange={ this.onAddCashCouponHandler }
				/>

				{
					isQOrder && (
						<ModalDepositAmount
							isOpen={ showModalDepositAmount }
							isFirstShown={ isFirstShownModalDepositAmount }
							depositAmount={ salesOrder.get('deposit_amount') }
							onToggle={ isFirstShownModalDepositAmount ? this.noOp : this.onToggleModalDepositAmountHandler }
							onChange={ this.onChangeDepositAmountHandler }
						/>
					)
				}
			</Fragment>
		);
	}
}

PageSalesOrderPayment.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	next: PropTypes.string.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onToggleModalSpecialOptions: PropTypes.func.isRequired,
	onChangeDepositAmount: PropTypes.func.isRequired,
	onAddTransactionPayment: PropTypes.func.isRequired,
	onChangeTransactionPayment: PropTypes.func.isRequired,
	onRemoveTransactionPayment: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		exchangeRatesInfo: state.exchangeRatesInfo,
		paymentsInfo: state.paymentsInfo,
	}),
	(dispatch) => ({
		getPayments: para => dispatch(getPayments(para)),
	})
)(PageSalesOrderPayment);