exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UX7O2 {\n  text-transform: capitalize;\n  background-color: #F6F4F0;\n  padding: 10px;\n  font-weight: bold;\n  color: #A89A72;\n}\n._3Lk1z {\n  display: inline-block;\n  width: 50px;\n  text-align: center;\n}\n._16GTb {\n  display: inline-block;\n  width: 100%;\n}\n._16GTb::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n._16GTb::-moz-placeholder {\n  text-transform: capitalize;\n}\n._16GTb:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._16GTb::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n._16GTb::placeholder {\n  text-transform: capitalize;\n}\n._3dS3a {\n  padding-right: 5px;\n}\n._1aPlI {\n  padding-left: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"label": "UX7O2",
	"unit": "_3Lk1z",
	"input": "_16GTb",
	"buttonColumnLeft": "_3dS3a",
	"buttonColumnRight": "_1aPlI"
};