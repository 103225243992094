exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2xN77 {\n  width: 100%;\n  height: calc(100% - 53px);\n}\n._3naw5 {\n  width: calc(100% - 315px);\n}\n._34Jtc {\n  margin-left: auto;\n  margin-right: 15px;\n  width: 315px;\n}\n._34Jtc ._3duJL canvas {\n  border-color: #a89a72 !important;\n}\n._34Jtc ._3duJL canvas {\n  margin: 0 auto 20px;\n  border: 1px solid #a89a72;\n}\n._34Jtc ._3eYPU {\n  width: 315px;\n}\n._34Jtc ._3eYPU button {\n  width: 300px;\n}\n", ""]);

// Exports
exports.locals = {
	"pdf": "_2xN77",
	"left": "_3naw5",
	"right": "_34Jtc",
	"signature": "_3duJL",
	"buttons": "_3eYPU"
};