import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import loc from 'Components/languages';
import { convertFloatToPrice } from 'Helpers/helpers';
import { getExchangeRates } from 'Redux/actions';
import styles from './ExchangeRateBox.less';

class ExchangeRateBox extends PureComponent {
	constructor() {
		super();
		this.isQOrder = this.isQOrder.bind(this);
		this.renderExchangeRate = this.renderExchangeRate.bind(this);
	}

	componentDidMount() {
		this.props.getExchangeRates();
	}

	isQOrder() {
		const { salesOrder } = this.props;
		return salesOrder.get('doc_type') === 'DP1';
	}

	renderExchangeRate(exchangeRate, index) {
		const { salesOrder } = this.props;
		return (
			<tr key={ `exchange-rate-box-tr-${index}` }>
				<td>{ exchangeRate.curr_code }</td>
				<td>{ exchangeRate.exch_rate }</td>
				<td>{ convertFloatToPrice(salesOrder.get('remaining_amount') / exchangeRate.exch_rate) }</td>
			</tr>
		);
	}

	render() {
		const { exchangeRatesInfo } = this.props;
		if (
			exchangeRatesInfo &&
			exchangeRatesInfo.data &&
			exchangeRatesInfo.data.show_exchange_rate
		) {
			return (
				<div className={ styles.container }>
					<table>
						<thead>
							<tr>
								<th>{ loc.currencyCode }</th>
								<th>{ loc.exchangeRate }</th>
								<th>{ loc.amount2 }</th>
							</tr>
						</thead>
						<tbody>
							{
								exchangeRatesInfo &&
								exchangeRatesInfo.data &&
								exchangeRatesInfo.data.data &&
								exchangeRatesInfo.data.data.map(this.renderExchangeRate)
							}
						</tbody>
					</table>
				</div>
			);
		}
		return null;
	}
}

ExchangeRateBox.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
};

export default connect(
	(state) => ({
		exchangeRatesInfo: state.exchangeRatesInfo,
	}),
	(dispatch) => ({
		getExchangeRates: para => dispatch(getExchangeRates(para)),
	})
)(ExchangeRateBox);