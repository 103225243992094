import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Map } from 'immutable';
import PaymentMethodButton from './PaymentMethodButton.jsx';
import loc from 'Components/languages';
import styles from './PaymentMethods.less';

class PaymentMethods extends PureComponent {
	render() {
		const { salesOrder, paymentsInfo, onSelect } = this.props;
		const allowedReturnPaymentMethods = new Map(salesOrder.get('allowed_return_payment_methods'));
		return (
			<div className={ classnames('payMetContainer', styles.container) }>
				<div className="uk-width-5-6">
					<h4 className="uk-text-uppercase">{ loc.methods }</h4>
					<div
						className="uk-flex uk-flex-wrap uk-flex-top"
						data-uk-height-viewport="offset-top: true; offset-bottom: 284px"
						data-uk-overflow-auto="selContainer: .stepContainer; selContent: .payMetContainer"
					>
						{
							(paymentsInfo && paymentsInfo.data && paymentsInfo.data.length > 0) ? paymentsInfo.data.map((paymentInfo, index) => (
								(!allowedReturnPaymentMethods || allowedReturnPaymentMethods.size === 0 || allowedReturnPaymentMethods.has(paymentInfo.code)) && (
									<PaymentMethodButton
										key={ `payment-method-container-${index}` }
										paymentMethod={ paymentInfo }
										onSelect={ onSelect }
									/>
								)
							)) : (
								<Fragment>
									<PaymentMethodButton paymentMethod={{}} />
									<PaymentMethodButton paymentMethod={{}} />
									<PaymentMethodButton paymentMethod={{}} />
									<PaymentMethodButton paymentMethod={{}} />
									<PaymentMethodButton paymentMethod={{}} />
									<PaymentMethodButton paymentMethod={{}} />
								</Fragment>
							)
						}
					</div>
				</div>
				<div className={ styles.placeholder } />
			</div>
		);
	}
}

PaymentMethods.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		paymentsInfo: state.paymentsInfo,
	})
)(PaymentMethods);