import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './PaymentMethodButton.less';

class PaymentMethodButton extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		e.preventDefault();
		const { paymentMethod, onSelect } = this.props;
		onSelect(paymentMethod);
	}

	render() {
		const { paymentMethod } = this.props;
		return (
			<div className={ classnames('uk-card uk-card-hover uk-padding-small uk-padding-remove-vertical uk-width-small uk-margin-medium-left uk-margin-medium-bottom', styles.button) } onClick={ this.onClickHandler }>
				<div className="uk-card-media-top">
					{
						paymentMethod.pay_icon ? (
							<img src={ `data:image/png;base64,${paymentMethod.pay_icon}` } className={ styles.icon } />
						) : (
							<div className={ styles.placeholder } />
						)
					}
				</div>
				<div className="uk-card-body uk-text-center uk-text-secondary uk-padding-small">
					{ paymentMethod.description }
				</div>
				<hr className="uk-margin-remove" />
			</div>
		);
	}
}

PaymentMethodButton.propTypes = {
	paymentMethod: PropTypes.object.isRequired,
	onSelect: PropTypes.func,
};

export default PaymentMethodButton;