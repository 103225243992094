import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import SignatureCanvas from 'react-signature-canvas';
import printJS from 'print-js';
import moment from 'moment';
import classnames from 'classnames';
import { previewOrder, viewOrder, printOrder } from 'Redux/actions';
import * as cons from 'Redux/constants';
import Button from 'Components/button';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import styles from './PageSalesOrderPrint.less';

class PageSalesOrderPrint extends PureComponent {
	constructor() {
		super();
		this.state = {
			triggerPrintDialog: true,
		};
		this.signatureCanvasProps = {
			style: {
				width: 300,
				height: 116,
				backgroundColor: 'white',
				borderColor: 'black'
			},
			onContextMenu: this.onContextMenuHandler.bind(this),
		};
		this.isQOrder = this.isQOrder.bind(this);
		this.setSignatureCanvasRef = this.setSignatureCanvasRef.bind(this);
		this.onEndSignatureHandler = this.onEndSignatureHandler.bind(this);
		this.onClickContinueHandler = this.onClickContinueHandler.bind(this);
		this.onClearHandler = this.onClearHandler.bind(this);
		this.onCreateNewOrderHandler = this.onCreateNewOrderHandler.bind(this);
		this.onClickPrintHandler = this.onClickPrintHandler.bind(this);
		this.onClickDownloadHandler = this.onClickDownloadHandler.bind(this);
	}

	componentDidMount() {
		const { signature } = this.props;
		if (signature) {
			this.signatureCanvasRef.fromDataURL(signature);
		}
	}

	componentDidUpdate(prevProps) {
		const viewOrderInfo = this.props.viewOrderInfo;
		const prevViewOrderInfo = prevProps.viewOrderInfo;
		if (viewOrderInfo && viewOrderInfo !== prevViewOrderInfo) {
			switch (viewOrderInfo.type) {
			case cons.VIEW_ORDER.SUCCESS:
				if (this.state.triggerPrintDialog) {
					this.setState({
						triggerPrintDialog: false,
					}, this.props.onPrint);
				}
				break;
			}
		}

		const printOrderInfo = this.props.printOrderInfo;
		const prevPrintOrderInfo = prevProps.printOrderInfo;
		if (printOrderInfo && printOrderInfo !== prevPrintOrderInfo) {
			switch (printOrderInfo.type) {
			case cons.PRINT_ORDER.SUCCESS:
				printJS({
					printable: printOrderInfo.data.pdf,
					type: 'pdf',
					base64: true
				});
				break;
			case cons.PRINT_ORDER.FAILURE:
				alert(`${loc[printOrderInfo.error.code]} (${printOrderInfo.error.code})`);
				break;
			}
		}
	}

	isQOrder() {
		const { salesOrder } = this.props;
		return salesOrder.get('doc_type') === 'DP1';
	}

	setSignatureCanvasRef(ref) {
		this.signatureCanvasRef = ref;
	}

	onEndSignatureHandler() {
		const { onChangeSignature } = this.props;
		onChangeSignature(this.signatureCanvasRef.toDataURL());
	}

	onClearHandler() {
		const { onChangeSignature } = this.props;
		onChangeSignature(null);
		this.signatureCanvasRef.clear();
	}

	onClickContinueHandler(e) {
		e.preventDefault();
		const { next, onStepClick, isLoading } = this.props;
		if (!isLoading) {
			onStepClick(next);
		}
	}

	onCreateNewOrderHandler() {
		const { onReset } = this.props;
		this.onClearHandler();
		onReset();
	}

	onClickPrintHandler() {
		this.props.onPrint();
	}

	onClickDownloadHandler() {
		const { viewOrderInfo } = this.props;
		if (
			viewOrderInfo && viewOrderInfo.data && viewOrderInfo.data.pdf
		) {
			const linkSource = `data:application/pdf;base64,${viewOrderInfo.data.pdf}`;
			const downloadLink = document.createElement('a');
			const fileName = `${viewOrderInfo.data.transaction_number}.pdf`;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	}

	onContextMenuHandler(e) {
		e.preventDefault();
	}

	render() {
		const { isLoading, preview, previewOrderInfo, viewOrderInfo } = this.props;
		const transactionDate = (!preview && viewOrderInfo && viewOrderInfo.data && viewOrderInfo.data.transaction_date) ? moment(viewOrderInfo.data.transaction_date) : null;
		const info = preview ? previewOrderInfo : viewOrderInfo;
		const isQOrder = this.isQOrder();
		return (
			<LoadingOverlay active={ isLoading }>
				<div
					className="stepContainer uk-height-large uk-position-relative"
					data-uk-height-viewport="offset-top: true; offset-bottom: 84px"
				>
					<div className="uk-flex-inline uk-width-1-1 uk-height-1-1">
						<div className={ classnames('uk-width-3-4', styles.left) }>
							<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">
								{ preview ? loc.preview : loc.print }
								{
									!isLoading && !preview && viewOrderInfo && viewOrderInfo.data && (
										<small className="uk-margin-left">
											Ordered on { transactionDate ? transactionDate.format('YYYY-MM-DD HH:mm:ss') : '' } / Order# { viewOrderInfo.data.transaction_number }
										</small>
									)
								}
							</h3>

							<LoadingOverlay active={ info.isFetching } className="uk-height-1-1">
								{
									(info && info.data && info.data.pdf) ? (
										<iframe
											src={ `data:application/pdf;base64,${info.data.pdf}` }
											className={ styles.pdf }
										/>
									) : (
										<div />
									)
								}
							</LoadingOverlay>
						</div>
						<div className={ classnames('uk-width-1-4 uk-position-relative uk-padding-small uk-padding-remove-vertical uk-padding-remove-right', styles.right) }>
							<h3>{ loc.customerSignature }</h3>
							<div className={ classnames('uk-flex', 'uk-flex-column', styles.signature) } data-uk-height-viewport="offset-top: true; offset-bottom: 15">
								<SignatureCanvas
									ref={ this.setSignatureCanvasRef }
									onEnd={ this.onEndSignatureHandler }
									canvasProps={ this.signatureCanvasProps }
									clearOnResize={ false }
									minWidth={ 2 }
									maxWidth={ 3 }
								/>

								<button
									className="uk-button uk-button-default uk-text-uppercase"
									onClick={ this.onClearHandler }
								>
									{ loc.clear }
								</button>
							</div>
							<div className={ classnames('uk-position-bottom-left', 'uk-width-1-1', 'uk-padding-small', 'uk-padding-remove-vertical', 'uk-padding-remove-right', styles.buttons) }>
								{
									preview ? (
										<Fragment>
											<Button
												text={ isQOrder ? loc.placeOrder : loc.continue }
												theme="primary"
												arrow={ true }
												disabled={ isLoading }
												onClick={ this.onClickContinueHandler }
											/>
										</Fragment>
									) : (
										<Fragment>
											<button
												className="uk-button uk-button-default uk-margin-small-bottom uk-width-1-1"
												onClick={ this.onCreateNewOrderHandler }
												disabled={ isLoading || viewOrderInfo.isFetching }
											>
												{ loc.createNewOrder }
											</button>
											{
												viewOrderInfo.data && (
													<Fragment>
														<br />
														{
															viewOrderInfo.data.pdf && (
																<Button
																	text={ loc.download }
																	theme="primary"
																	className="uk-width-1-1 uk-margin-small-bottom"
																	disabled={ isLoading || viewOrderInfo.isFetching }
																	onClick={ this.onClickDownloadHandler }
																/>
															)
														}
														{
															viewOrderInfo.data.allow_printing && (
																<Button
																	text={ loc.printInvoice }
																	theme="primary"
																	className="uk-width-1-1"
																	arrow={ true }
																	disabled={ isLoading || viewOrderInfo.isFetching }
																	onClick={ this.onClickPrintHandler }
																/>
															)
														}
													</Fragment>
												)
											}
										</Fragment>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

PageSalesOrderPrint.propTypes = {
	preview: PropTypes.bool.isRequired,
	next: PropTypes.string,
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onToggleModalSpecialOptions: PropTypes.func.isRequired,
	signature: PropTypes.string,
	onChangeSignature: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		previewOrderInfo: state.previewOrderInfo,
		viewOrderInfo: state.viewOrderInfo,
		printOrderInfo: state.printOrderInfo,
	}),
	dispatch => ({
		previewOrder: para => dispatch(previewOrder(para)),
		viewOrder: para => dispatch(viewOrder(para)),
		printOrder: para => dispatch(printOrder(para)),
	})
)(PageSalesOrderPrint);