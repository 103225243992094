import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import thousands from 'thousands';
import InputText from 'Components/input-text';
import styles from './PaymentDetailField.less';

class PaymentDetailField extends PureComponent {
	constructor() {
		super();
		this.state = {
			value: 0,
			isFocused: false,
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onBlurHandler = this.onBlurHandler.bind(this);
		this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
	}

	componentDidMount() {
		const { value } = this.props;
		this.setState({
			value: value || 0,
		});
	}

	componentDidUpdate(prevProps) {
		const value = this.props.value;
		const prevValue = prevProps.value;
		if (value !== prevValue) {
			this.setState({
				value: value || 0,
			});
		}

		if (!this.state.isFocused && value !== this.state.value) {
			this.setState({
				value,
			});
		}
	}

	onChangeHandler(e) {
		this.setState({
			value: Math.min(parseFloat(e.target.value) || 0, 999999999),
			isFocused: true,
		});
	}

	onClickHandler() {
		const { onClick } = this.props;
		onClick && onClick();
	}

	onBlurHandler() {
		const { value } = this.state;
		const { onChange } = this.props;
		this.setState({
			isFocused: false,
		});
		onChange(value);
	}

	onKeyDownHandler(e) {
		if (e.key === 'Enter') {
			e.target.blur();
			this.onBlurHandler();
		}
	}

	render() {
		const { label, hideValue, onChange, onClick, autoFocus } = this.props;
		const { value } = this.state;
		return (
			<div className={ classnames(styles.field, 'uk-flex uk-flex-middle') }>
				<div className="uk-width-2-5 uk-text-secondary uk-h5 uk-margin-remove uk-text-uppercase">
					{ label } :
				</div>
				<div className="uk-width-2-5 uk-text-bold" onClick={ this.onClickHandler }>
					{
						onChange ? (
							<div className="uk-flex uk-flex-middle">
								{ '$ ' }
								<InputText
									type="number"
									value={ value }
									placeholder="0"
									autoFocus={ autoFocus }
									onChange={ this.onChangeHandler }
									onBlur={ this.onBlurHandler }
									onKeyDown={ this.onKeyDownHandler }
								/>
							</div>
						) : (
							!hideValue && (
								<div className={ classnames(onClick && styles.clickable) }>
									{
										onClick ? `$ ${thousands(value)}` : value
									}
								</div>
							)
						)
					}
				</div>
				<div className="uk-width-1-5" />
			</div>
		);
	}
}

PaymentDetailField.propTypes = {
	label: PropTypes.string.isRequired,
	autoFocus: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	hideValue: PropTypes.bool,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

export default PaymentDetailField;