import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Modal from 'Components/modal';
import Button from 'Components/button';
import loc from 'Components/languages';
import * as cons from 'Redux/constants';
import { findCashCouponByInput } from 'Redux/actions';
import styles from './Modal.less';

class ModalCashCoupon extends PureComponent {
	constructor() {
		super();
		this.defaultState = {
			coupon_code: null,
			isSubmitted: false,
		};
		this.state = {
			...this.defaultState,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '150px',
			},
		};
		this.validateCoupon = this.validateCoupon.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		const cashCouponInfo = this.props.cashCouponInfo;
		const prevCashCouponInfo = prevProps.cashCouponInfo;
		if (isOpen && isOpen !== prevIsOpen) {
			this.setState({
				...this.defaultState,
			});
		}

		if (cashCouponInfo && cashCouponInfo !== prevCashCouponInfo) {
			const { onChange, onToggle } = this.props;
			const { coupon_code } = this.state;
			switch (cashCouponInfo.type) {
			case cons.FIND_CASH_COUPON_BY_INPUT.SUCCESS:
				if (this.validateCoupon(cashCouponInfo.data)) {
					this.setState({
						...this.defaultState,
					});
					onChange({
						coupon_code,
						amount: Math.min(parseFloat(cashCouponInfo.data.coupon_amount), 999999999),
					});
					onToggle();
				} else {
					this.setState({
						isSubmitted: false,
					});
				}
				break;
			case cons.FIND_CASH_COUPON_BY_INPUT.FAILURE:
				alert(loc.MSGItemNotFound);
				this.setState({
					isSubmitted: false,
				});
				break;
			}
		}
	}

	validateCoupon(couponResponse) {
		if (couponResponse.is_valid_coupon === false) {
			alert(loc.MSGCouponInvalid);
			return false;
		}
		if (couponResponse.is_expired_coupon === true) {
			alert(loc.MSGCouponExpired);
			return false;
		}
		if (couponResponse.is_for_current_shop === false) {
			alert(loc.MSGCouponNotForCurrentShop);
			return false;
		}
		return true;
	}

	onChangeHandler(e) {
		let { id, value } = e.target;
		let newState = {};
		newState[id] = value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { coupon_code } = this.state;
		this.props.findCashCouponByInput({
			payload: {
				value: coupon_code,
			},
		});
		this.setState({
			isSubmitted: true,
		});
	}

	onCancelHandler() {
		const { onToggle } = this.props;
		this.setState(this.defaultState, onToggle);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		let { coupon_code, isSubmitted } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<form className="uk-form-stacked" onSubmit={ this.onSubmitHandler }>
					<div className="uk-margin">
						<label className={ classnames('uk-form-label', styles.label) }>{ loc.coupon }</label>
						<div className="uk-form-controls">
							<input
								id="coupon_code"
								className={ classnames('uk-input', styles.input) }
								type="text"
								autoFocus
								placeholder={ loc.coupon }
								value={ coupon_code }
								onChange={ this.onChangeHandler }
								disabled={ isSubmitted }
							/>
						</div>
					</div>

					<div className="uk-margin-small-top uk-text-center uk-flex">
						<div className={ classnames('uk-width-1-2', styles.buttonColumnLeft) }>
							<Button
								text={ loc.cancel }
								theme="primary"
								type="button"
								className="uk-width-1-1"
								onClick={ this.onCancelHandler }
								disabled={ isSubmitted }
							/>
						</div>
						<div className={ classnames('uk-width-1-2', styles.buttonColumnRight) }>
							<Button
								text={ loc.apply }
								theme="primary"
								type="submit"
								className="uk-width-1-1"
								onClick={ this.onSubmitHandler }
								disabled={ isSubmitted }
							/>
						</div>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalCashCoupon.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		cashCouponInfo: state.cashCouponInfo,
	}),
	(dispatch) => ({
		findCashCouponByInput: para => dispatch(findCashCouponByInput(para)),
	})
)(ModalCashCoupon);